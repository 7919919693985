$images:        '../images';
$sprite-url:    url('#{$images}/sprite.png');
$fonts:         '../fonts';
$pre:           'pr-';

// Margin, padding
$mp-primary: 20px;
$grid-gutter-width: 40px;
$p-content: 15px;

// Base
$body-bg: #d9d9da;
$brand-primary: #848484;
$brand-secondary: #fff;
$c-brand-primary-hover: darken($brand-primary, 4%);
$gray-light: #808285;
$bg-light: #fff;
$bg-light-hover: darken(#fff, 3%);
$bg-xlight-hover: darken(#fff, 1%);
$fw-letter-spacing: 0 !default;

$brand-danger: #f1204c;
$brand-danger-light: lighten($brand-danger, 40%);
$state-danger-text: $brand-danger;
$state-danger-bg: lighten($brand-danger, 90%);
$state-danger-border: $brand-danger;

$screen-xlg-min: 1300px;
$screen-lg-max: ($screen-xlg-min - 1);
$w-page-min: 320px;
$w-page-max: 767px;
$w-page-min-desktop: 960px;
$w-page-max-desktop: 100%;


// Fonts
$font-family-base: 'Dosis' !default;
$font-size-base: 14px !default;
$font-size-large: 19px !default;
$line-height-base: 1.5;
$lh-small: 1.15;
$text-color: $gray-light;
$fw-base: 500 !default;
$fz-checkbox-label: $font-size-large;
$fz-checkbox-label-desktop: 14px !default;


// Buttons
$btn-primary-color: $brand-secondary;
$border-radius-base: 4px;
$btn-border-radius-base: 22px;
$h-button: 34px;
$fw-button: 500;
$fz-button-desktop: 14px;
$btn-default-bg: $gray-light;
$btn-default-border: $gray-light;
$btn-default-color: #fff;
$fz-button-inner-header: $font-size-large;
$mw-button: 270px;


// Header
$bg-header: $bg-light;
$bg-header-desktop: #f4f4f4;
$bb-header-desktop: $body-bg;
$h-header: 60px;
$h-header-desktop: 75px;
$bxsh-header: 0 0 4px rgba(41, 54, 76, .25);
$z-header: 7;


// Headings
$font-size-h1: $font-size-large;
$font-size-h2: $font-size-large;
$font-size-h1-desktop: 18px !default;
$headings-line-height: $lh-small;


// Navbar
$navbar-border-radius: 50%;
$c-navbar-toggle-icon-bar: $brand-secondary;
$h-navbar-toggle: $h-button;


// Navs
$bg-box: $bg-light;
$bg-box-hover: $bg-light-hover;
$bxsh-box: $bxsh-header;
$fz-box: $font-size-large;
$c-box: $brand-primary;


// User menu
$w-side-menu: 280px;
$bg-side-menu: $brand-primary;
$c-side-menu: $brand-secondary;
$bg-side-menu-light: #fff;
$c-side-menu-light: $brand-primary;
$bg-side-menu-overlay: #02151e;
$op-side-menu-overlay: 0.75;
$z-side-menu: 12;
$time-side-menu: 0.2s;
$h-side-menu-icon: 37px;
$h-side-menu-icon-xs: 30px;
$w-side-menu-icon-desktop: 25px;
$h-side-menu-icon-desktop: 26px;
$c-side-menu-icon-desktop: $brand-primary;
$fz-side-menu: $font-size-large;
$fz-side-menu-xs: 17px;

$h-media-md: 670px;
$h-media-sm: 520px;

// System picker
$fz-system-picker-small: 13px;
$c-system-picker-small: $gray-light;
$w-system-picker-desktop: 320px;
$w-system-picker-desktop-lg: 235px;
$w-system-picker-desktop-xlg: 390px;


// Desktop left menu
$w-left-menu-desktop: 250px;
$w-left-menu-desktop-lg: 190px;
$bg-left-menu-desktop: $brand-primary;

// Desktop content
$p-content-desktop: 60px 100px 60px ($w-left-menu-desktop + 100);
$p-content-desktop-lg: 60px 80px 60px ($w-left-menu-desktop-lg + 80);
$bg-content-desktop: #fff;
$bxsh-content-desktop: 0 0 5px rgba(41, 54, 76, .25);


// Authentification
$bg-auth: #fff;
$fz-auth-button: $font-size-large;
$p-auth-desktop: 75px;
$w-auth-desktop: 430px;


// Forms
$input-border: #818285;
$input-color-placeholder: $input-border;
$input-height-base: 43px;
$input-height-base-desktop: 34px;
$fz-input-desktop: 14px;
$fz-label-desktop: 14px;
$input-border-radius: ($input-height-base / 2);
$input-border-radius-desktop: ($input-height-base-desktop / 2);
$form-group-margin-bottom: 20px;
$form-group-margin-bottom-auth: 30px;
$form-group-margin-bottom-auth-xs: 20px;
$form-group-margin-bottom-auth-desktop: 20px;
$form-group-margin-bottom-auth-desktop-sm: 15px;
$bdc-checkbox: $input-border;
$bdc-checkbox-disabled: lighten($bdc-checkbox, 20%);
$size-checkbox: 30px;
$size-radio-checked: 14px;
$pos-radio-checked: (($size-checkbox - $size-radio-checked) / 2);
$p-input-horizontal: 25px;
$bg-datepicker-intervals: #d9d9da;
$bg-datepicker-intervals-hover: darken($bg-datepicker-intervals, 4%);


// Popups
$bg-popup: #fff;
$op-popup-overlay: $op-side-menu-overlay;
$bg-popup-overlay: rgba($bg-side-menu-overlay, $op-side-menu-overlay);

$lh-popup-notification: 1.2;
$pt-popup-notification: 70px;
$bg-popup-notification-icon: #fff;
$w-popup-notification-icon: 100px;
$w-popup-notification-icon-icon: 27px;
$w-popup-notification-icon-icon-danger: 37px;
$bdw-popup-notification-icon: 5px;
$bdc-popup-notification-icon: #415056;
$z-popup: ($z-side-menu + 1);


// Areas
$z-menu: 5;
$bxsh-area: $bxsh-header;
$w-area-icon: 50px;
$h-area-icon: 40px;
$w-area-danger-icon: 40px;
$h-area-danger-icon: $w-area-danger-icon;
$c-area-name: $brand-secondary;


// Status slider
$br-status-slider: $input-border-radius;
$bc-status-slider-on: $brand-primary;
$bc-status-slider-off: $gray-light;
$c-status-slider: $brand-secondary;
$w-status-slider: 85px;
$p-status-slider: 3px;
$lh-status-slider: ($h-button - 2 * $p-status-slider);


// List
$wp-list-limit-md: 580px;
$w-list-limit-md: 200px;
$wp-list-limit-xs: 450px;
$w-list-limit-xs: 108px;
$w-list-limit: 280px;


// Keypad
$w-keypad: 250px;
$w-keypad-sm: 200px;
$fz-keypad-key: 25px;
$fz-keypad-key-desktop: 20px;
$fz-keypad-key-xs: 20px;
$w-keypad-key: 60px;
$h-keypad-key: $w-keypad-key;
$w-keypad-key-sm: 50px;
$h-keypad-key-sm: $w-keypad-key-sm;
$w-keypad-key-xs: 40px;
$h-keypad-key-xs: $w-keypad-key-xs;
$w-keypad-backspace: 30px;
$c-keypad-action-cancel: $gray-light;
$h-keypad-action-cancel: 20px;
$h-keypad-action-cancel-sm: 15px;
$c-keypad-action-backspace: inherit;
$h-keypad-action-backspace: 30px;
$h-keypad-action-backspace-sm: 20px;
$fz-keypad-code: 30px;
$fz-keypad-code-xs: 25px;
$c-keypad-code: $brand-primary;
$wp-keypad-sm: 590px;
$wp-keypad-xs: 470px;

// Cameras
$bxsh-camera: $bxsh-header;

$w-xpull: 35px;
$z-xpull: ($z-header - 1);




// ROBOTO Changes
$font-family-base: 'Roboto Condensed';
// $font-size-base: 12px;
// $font-size-large: 17px;
// $fw-base: 400;
// $fz-checkbox-label-desktop: 12px;
// $font-size-h1-desktop: 16px;
// $fw-letter-spacing: -0.2px;
